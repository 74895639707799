import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <article id="main">
      <header>
        <h2>Awards</h2>
        {/*<p>Aliquam ut ex ut interdum donec amet imperdiet eleifend</p>*/}
      </header>
    </article>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <ul className="features">
          <li className="icon solid fa-globe">
            <p><b>The Liburdi Family Foundation International Exchange Scholarship</b></p>
            <p>
              Awarded to a in the Faculty of Engineering who attains high averages and will participate in one of McMaster’s formal exchange programs. I will be going on exchange to KTH Royal Institute of Technology in Stockholm, Sweden.
            </p>
          </li>
          <li className="icon solid fa-hands-helping">
            <p><b>The PCL Scholarship in Engineering and Management</b></p>
            <p>
              Awarded to a student who has completed Level III of an Engineering and Management program and who, in the judgment of the Faculty of Engineering, has achieved notable academic standing and has made a significant 
              contribution to university life through extra-curricular activities.
            </p>
          </li>
          <li className="icon solid fa-piggy-bank">
            <p><b>RBC Insurance Athletic Leadership Award</b></p>
            <p>
              An award that recognizes student‐athletes that have demonstrated leadership qualities and a commitment to community engagement.
            </p>
          </li>
          <li className="icon solid fa-briefcase">
            <p><b>McMaster Engineering Co-op & Career Services Future Leader</b></p>
            <p>
              Awarded to students who made a significant impact to the McMaster Engineering Co-op education program.
            </p>
          </li>
          <li className="icon solid fa-cogs">
            <p><b>Faculty of Engineering Outreach Outstanding Committee Member</b></p>
            <p>
              In recognition of a Faculty of Engineering Student Ambassador on the Digital Ambassadors committee who made an outstanding contribution in supporting recruitment intiatives.
            </p>
          </li>
          <li className="icon solid fa-plane-departure">
            <p><b>Global Challenge Award</b></p>
            <p>
              Awarded for my perspective on virtual global opportunities, including the benefits of virtual opportunities and what outcomes students can gain from these opportunities.
            </p>
          </li>
        </ul>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
